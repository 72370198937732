import { useCallback, useEffect, useState } from "react";
import Web3Modal from "web3modal";
import Web3 from "web3";
//import Authereum from 'authereum';
//import ethProvider from 'eth-provider'; // Frame provider
import { initContractsWithWeb3 } from "./contracts/Contracts";
import { loadTokensPerMonth } from "./Api";

const useConnection = () => {
  const [provider, setProvider] = useState(null);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [web3Modal, setWeb3Modal] = useState(null);
  const [connection, setConnection] = useState({});
  const [networkId, setNetworkId] = useState(0);
  const [userConnected, setUserConnected] = useState(false);


  const updateProvider = useCallback(
    newProvider => {
      if (!userConnected) {
        return;
      }
      // Unlock issues:
      //https://github.com/MetaMask/metamask-extension/issues/10368

      // Subscribe to accounts change
      newProvider.currentProvider.on("accountsChanged", accounts => {
        setCurrentAddress(accounts.length ? accounts[0] : null);
      });

      // Subscribe to chainId change
      newProvider.currentProvider.on("chainChanged", chainId => {
        setNetworkId(chainId);
      });

      setProvider(newProvider);
    },
    [userConnected]
  );

  const connectToModal = useCallback(
    async () => {
      setUserConnected(true);

      console.log("connectTomodal");
      if (!web3Modal) {
        return;
      }

      await web3Modal.clearCachedProvider();
      let currProvider = await web3Modal.connect();
      //const info = await getProviderInfo(currProvider);

      window.web3 = new Web3(currProvider);
      updateProvider(window.web3);
      setNetworkId(getNetworkId());
      await updateCurrentAddress();
    },
    [web3Modal, updateProvider, userConnected]
  );

  const updateCurrentAddress = async () => {
    if (!userConnected) {
      return;
    }
    const address = await window.web3.eth
      .getAccounts()
      .then(function(accounts) {
        return accounts[0];
      });

    setCurrentAddress(address);
    return address;
  };

  const getNetworkId = () => {
    if (!userConnected) {
      return;
    }
    if (!window.web3.currentProvider) {
      return 0;
    }

    if (window.web3.currentProvider.isMetaMask) {
      return window.web3.currentProvider.networkVersion;
    } else {
      return 0;
    }
  };

  const getCurrentAddress = useCallback(
    async () => {
      if (!userConnected) {
        return;
      }
      while (currentAddress === null) {
        const currAddress = await updateCurrentAddress();
        return currAddress;
      }
    },
    [currentAddress, userConnected]
  );

  // Instantiate a web3Modal
  useEffect(
    () => {
      if (!userConnected) {
        return;
      }
      if (!window.web3Modal) {
        const providerOptions = {
          /*
        authereum: {
          package: Authereum
        },
        frame: {
          package: ethProvider
        },
        */
        };

        window.web3Modal = new Web3Modal({
          // TODO: change to mainnet
          network: "mainnet",
          cacheProvider: true,
          providerOptions
        });
      }

      window.web3Modal.connect().then(async currProvider => {
        window.web3 = new Web3(currProvider);
        await updateCurrentAddress();
        await initContractsWithWeb3(window.web3);
        updateProvider(window.web3);
        setNetworkId(getNetworkId());
        loadTokensPerMonth();
      });

      setWeb3Modal(window.web3Modal);
    },
    [updateProvider, userConnected]
  );

  useEffect(async () => {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://mainnet.infura.io/v3/bd5ceba0f20d417ca6dcf2cb43cdc78c"
      )
    );
    await initContractsWithWeb3(web3);
    setConnection({ provider: web3, connectToModal: connectToModal });
  }, []);

  useEffect(
    () => {
      setConnection({
        provider,
        currentAddress,
        connectToModal,
        getCurrentAddress,
        networkId
      });
    },
    [
      provider,
      currentAddress,
      connectToModal,
      getCurrentAddress,
      networkId,
      userConnected
    ]
  );

  return connection;
};

export default useConnection;
