import React from 'react';
import { NavLink } from 'react-router-dom';
import WalletMenuItem from './menu/WalletMenuItem';
import web3 from 'web3';

const Header = ({
    prices,
    gitcoinBalance,
    gitcoinHistorical,
    payoutBalance,
}) => {



    return (
        <>
            <div className="ui container header main-header">
                <div className="ui main menu">
                    <NavLink exact to="/" className="item" activeClassName="active">Home</NavLink>
                    <NavLink to="/my-nfts" className="item" activeClassName="active">My NFTS</NavLink>
                    <NavLink to="/how-it-works" className="item" activeClassName="active">How It Works</NavLink>
                    <NavLink to="/faq" className="item" activeClassName="active">Faq</NavLink>
                    <div className="item menu-price-item">

                        <div className="eth-btc-ratio">
                        { prices && prices.btcEthPrice ?
                            <div className="ui purple label eth-btc-label">
                                ETH/BTC
                                <div className="detail">
                                    {parseFloat(web3.utils.fromWei(prices.btcEthPrice.toString())).toFixed(3)}
                                </div>
                            </div>
                            : null
                        }
                        Flippening @ 6.1797
                        </div>


                        {payoutBalance ?
                            <div className="ui pink label eth-btc-label">
                                Current Pool
                                <div className="detail">
                                    {parseFloat(web3.utils.fromWei(payoutBalance.toString())).toFixed(3)} ETH
                                </div>
                            </div>
                            : null
                        }

                        <div className="ui teal label eth-btc-label">
                            Gitcoin Donations
                            {gitcoinBalance ?
                                <div className="detail">
                                    {parseFloat(web3.utils.fromWei(gitcoinBalance.toString())  + web3.utils.fromWei(gitcoinHistorical.toString())).toFixed(3) } ETH
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className="right menu">
                        <WalletMenuItem />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header