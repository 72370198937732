import React, {useContext, useState} from 'react';
import web3 from 'web3';
import {getFlippeningTime, recordFlippening, donateToGitcoin} from "../web3/Api";
import ConnectionContext from "../web3/ConnectionContext";

const Footer = ({isFlipped, flippeningTime, setFlippeningTime, gitcoinHistorical, gitcoinBalance}) => {
    const connection = useContext(ConnectionContext);
    const [isRedeeming, setIsRedeeming] = useState(false);
    const [isDonating, setIsDonating] = useState(false);

    const handleRecordFlippening = () => {
        setIsRedeeming(true);
        recordFlippening(connection.currentAddress).then(() => {
            getFlippeningTime().then((time) => setFlippeningTime(time));
        }).finally(() => {
            setIsRedeeming(false)
        });
    };

    const handleDonate = () => {
        setIsDonating(true);
        donateToGitcoin(connection.currentAddress).finally(() => {
            setIsDonating(false);
        });
    };

    return (
        <>
            <div className="ui container footer">
                <div className="ui segment">
                    contact: &#101;&#116;&#104;&#102;&#108;&#105;&#112;&#112;&#101;&#110;&#105;&#110;&#103;&#064;&#112;&#114;&#111;&#116;&#111;&#110;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;
               

                    { isFlipped && flippeningTime === '0' ?
                        <div className="ui green label eth-btc-label flipping-btn" onClick={handleRecordFlippening}>
                            {!isRedeeming ?
                                'Record Flippening'
                                :
                                <><div className="ui mini active inline inverted loader"></div> Recording</>
                            }
                        </div>
                        : null
                    }
                    {gitcoinBalance && gitcoinHistorical && <p>Gitcoin Donations include : {parseFloat(web3.utils.fromWei(gitcoinHistorical.toString())).toFixed(3)} ETH historical donations and: {parseFloat(web3.utils.fromWei(gitcoinBalance.toString())).toFixed(3)} ETH Current Balance</p>}
                    <div className="ui violet label eth-btc-label flipping-btn" onClick={handleDonate}>
                        {!isDonating ?
                            'Send Balance To Gitcoin'
                            :
                            <><div className="ui mini active inline inverted loader"></div> Donating</>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer