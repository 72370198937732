import React from 'react';
import howitworks from 'images/ETH_Large_InfoGraphic.jpg';
import web3 from 'web3';

const HowItWorks = () => {

    const prices = [
        10000000000000000,
        12500000000000000,
        15000000000000000,
        17500000000000000,
        20000000000000000,
        25000000000000000,
        30000000000000000,
        35000000000000000,
        40000000000000000,
        47500000000000000,
        55000000000000000,
        62500000000000000,
        70000000000000000,
        80000000000000000,
        90000000000000000,
        100000000000000000,
        110000000000000000,
        122500000000000000,
        135000000000000000,
        147500000000000000,
        160000000000000000,
        175000000000000000,
        190000000000000000,
        205000000000000000,
        220000000000000000,
        237500000000000000,
        255000000000000000,
        272500000000000000,
        290000000000000000,
        310000000000000000,
        310000000000000000,
    ];

    return (
        <>
            <div className="ui filter container">
                <div className="ui masthead container my-nfts">
                    <h3 className="ui top attached header page-header">
                        How it works
                    </h3>
                    <div className="ui attached segment">
                        <div className="ui weekly container">
                            <img
                                src={howitworks}
                                alt="how it works"
                                className="ui fluid bordered rounded image"
                            />
                            <table className="ui pink table">
                                <thead>
                                <tr>
                                    <th className="collapsing">Token Generated In Month</th>
                                    <th>Cost (ETH)</th>
                                </tr>
                                </thead>
                                <tbody>
                                {prices.map((price, idx) =>
                                    <tr key={idx}>
                                        <td className="ui center aligned">{idx + 1}</td>
                                        <td>{web3.utils.fromWei(web3.utils.toBN(price))}</td>
                                    </tr>)
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default HowItWorks;
