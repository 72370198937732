import { getBtcEthPriceContract } from './contracts/Contracts';
import { getFlippeningContract } from './contracts/Contracts';
import web3 from 'web3';
import { uniqBy } from 'lodash';
import { range } from 'lodash';


// And array of objects {tokenId: number, month: number, monthRank: number}
// ie, {tokenId: 5, month: 6, monthRank 3} = Token id was the 3rd token purchased in month 6



async function loadTokensPerMonth() {

    
    // Find the max tokens printed
  
    let numTokens = 0
    numTokens = await getFlippeningContract().methods.numTokens().call().then((numTokens) => numTokens)
    numTokens = parseInt(numTokens, 10)
    
    // Array saying how many tokens have been made per month
    // ie, tokensPerMonth[4] == 3 means that 3 tokens have been made in the 5th (index starts at 0) month
    let tokensPerMonth = [];

    let tokenMonthRank = [];

    let month;

    // Loading for the first time, start over
    if (!localStorage.getItem('tokensPerMonth')) {
       

        // initialize tokens per month
        for (let j = 0; j < 50; j++) {
            tokensPerMonth[j] = 0;
        }

        for (let i = 0; i < numTokens; i++) {
            month = await getFlippeningContract().methods.monthOf(i).call()
            month = parseInt(month, 10)
            tokensPerMonth[month] += 1;
            tokenMonthRank.push({
                tokenId: i,
                month: month,
                monthRank: tokensPerMonth[month],
            })
        }
      
    }

    // Previously Loaded tokens, just load new ones
    else {
        tokenMonthRank = JSON.parse(localStorage.getItem('tokenMonthRank'))
     
        let tempTokensPerMonth = JSON.parse(localStorage.getItem('tokensPerMonth'))
        
      
        tempTokensPerMonth.forEach((item) => {
            tokensPerMonth.push(parseInt(item, 10))
        })
    
        const oldNumTokens = parseInt(localStorage.getItem('numTokens'), 10)
     

        for (let k = oldNumTokens; k < numTokens; k++) {
            month = await getFlippeningContract().methods.monthOf(k).call()
            month = parseInt(month, 10)
            tokensPerMonth[month] += 1;
            tokenMonthRank.push({
                tokenId: k,
                month: month,
                monthRank: tokensPerMonth[month],
            })
        }
    }

    // Write current values to local storage
    localStorage.setItem('tokensPerMonth', JSON.stringify(tokensPerMonth))
    localStorage.setItem('tokenMonthRank', JSON.stringify(tokenMonthRank))
    localStorage.setItem('numTokens', numTokens)
}


async function getPastEvents(address, type) {
    let events = [];

    if (getFlippeningContract()) {
        events = await getFlippeningContract()
                    .getPastEvents(type, {
                        fromBlock: 'earliest',
                        toBlock: 'latest',
                        filter: {to: address}
                    })
                    .then(events => events);
    }

    return events;
}


async function getTokenInfoForMonth(monthIndex) {
    let tokensMinted = '0';
    let price = '0';
    let nextPrice = '0';

    if (monthIndex !== undefined && getFlippeningContract()) {
        tokensMinted = await getFlippeningContract().methods.madePerPeriod(monthIndex.toString()).call().then(result => result).catch(e => {  return '0'; });
        price = await getFlippeningContract().methods.prices(tokensMinted).call().then(result => result).catch(e => {  return '0'; });

        // max of 31 tokens in a month
        if (parseInt(tokensMinted) <= 30) {
            nextPrice = await getFlippeningContract().methods.prices(parseInt(tokensMinted)+1).call().then(result => result).catch(e => {  return '0'; });
        }
    }

    return {
        tokensMinted,
        price,
        nextPrice
    };
}

async function buyToken(currentAddress, monthIndex, value) {
    if (getFlippeningContract()) {
        const sendData = {
            value: value,
            from: currentAddress,
            gas: 200000,
        //    gasPrice: web3.utils.toWei('1', 'gwei')
        };

        await getFlippeningContract().methods.createNFT(monthIndex.toString()).send(sendData).then(result => result);
    }
}

async function transferToken(currentAddress, toAddress, tokenId) {
    if (getFlippeningContract()) {
        const sendData = {
            from: currentAddress,
            gas: 80000,
         //   gasPrice: web3.utils.toWei('1', 'gwei')
        };

        return getFlippeningContract().methods.safeTransferFrom(currentAddress, toAddress, tokenId).send(sendData);
    }
}

async function getOwnedTokens(currentAddress) {
    let tokens = [];

    if (window.web3.eth) {
        await getPastEvents(currentAddress, 'Transfer').then((events) => {
            tokens = events.map(event => {
                return {
                    tokenId: event.returnValues.tokenId,
                    blockNumber: event.blockNumber,
                    blockHash: event.blockHash,
                    transactionIndex: event.transactionIndex,
                    transactionHash: event.transactionHash,
                };
            });

            // The newest should be at the 0 position
            tokens.reverse();
        });
    }

    // Get the owners of tokens because tokens might have been transferred
    const tokenOwners = await Promise.all(
        tokens.map(token =>
            getFlippeningContract().methods.ownerOf(token.tokenId).call().then(result => result.toLowerCase()).catch(e => {  return ''; })
        )
    )

    // Get owned tokens
    tokens = tokens.filter((token, i) => tokenOwners[i] === currentAddress.toLowerCase());

    // Get unique by tokenId property
    tokens = uniqBy(tokens, 'tokenId');

    // Get token months
    const tokenMonths = await Promise.all(
        tokens.map(token =>
            getFlippeningContract().methods.monthOf(token.tokenId).call().then(result => result).catch(e => {  return ''; })
        )
    )

    // Get the owners of tokens because tokens might have been transferred
    const tokenClaims = await Promise.all(
        tokens.map(token =>
            getFlippeningContract().methods.tokenClaimed(token.tokenId).call().then(result => result).catch(e => {  return ''; })
        )
    )

    tokens.forEach((token, idx) => {
        token.month = tokenMonths[idx];
        token.claimed = tokenClaims[idx];
    });

    // Oldest will be at index 0
    tokens.reverse();

    return tokens;
}

async function getTokenInformation(tokenId) {
    let tokenInfo = null;

    if (getFlippeningContract()) {
        tokenInfo = {};
        const infoPromises = [
            getFlippeningContract().methods.monthOf(tokenId).call().then(result => result).catch(e => {  return ''; }),
            getFlippeningContract().methods.tokenClaimed(tokenId).call().then(result => result).catch(e => {  return ''; }),
            getFlippeningContract().methods.ownerOf(tokenId).call().then(result => result.toLowerCase()).catch(e => {  return ''; }),
            getFlippeningContract().methods.tokenURI(tokenId).call().then(result => result.toLowerCase()).catch(e => {  return ''; }),

        ];

        await Promise.allSettled(infoPromises).then(results => {
            tokenInfo = {
                tokenId : tokenId,
                month   : results[0].value,
                claimed : results[1].value,
                owner   : results[2].value,
                uri     : results[3].value
            }
        });
    }

    return tokenInfo;
}

async function getGitcoinBalance() {
    let gitcoinBalance = 0;

    if (getFlippeningContract()) {
        gitcoinBalance = await getFlippeningContract().methods.gitcoinBalance().call().then(result => result);
    }

    return gitcoinBalance;
}

async function getGitcoinHistorical() {
    let gitcoinHistorical = 0;

    if (getFlippeningContract()) {
        gitcoinHistorical = await getFlippeningContract().methods.gitcoinHistorical().call().then(result => result);
    }

    return gitcoinHistorical;
}

async function recordFlippening(currentAddress) {
    const sendData = {
        from: currentAddress,
        gas: 80000,
  //      gasPrice: web3.utils.toWei('1', 'gwei')
    };

    if (getFlippeningContract()) {
        await getFlippeningContract().methods.recordFlippening().send(sendData);
    }
}

async function donateToGitcoin(currentAddress) {
    const sendData = {
        from: currentAddress,
        gas: 80000,
     //   gasPrice: web3.utils.toWei('1', 'gwei')
    };

    if (getFlippeningContract()) {
        await getFlippeningContract().methods.donateToGitcoin().send(sendData);
    }
}

async function getFlippeningTime() {
    if (getFlippeningContract()) {
        return await getFlippeningContract().methods.flippeningTime().call().then(result => result);
    }

    return null;
}

async function getPayoutBalance() {
    if (getFlippeningContract()) {
        return await getFlippeningContract().methods.payoutBalance().call().then(result => result);
    }

    return null;
}

async function getPrice() {
    let btcEthPrice = 0;

    if (getBtcEthPriceContract()) {
        btcEthPrice = await getBtcEthPriceContract().methods.latestRoundData().call().then(result => result.answer);
    }

    return {
        btcEthPrice
    };
}

async function getTokensPerMonth() {
    if (getFlippeningContract()) {
        const tokenPromises = range(0, 48).map(month => {
            return getFlippeningContract().methods.madePerPeriod(month).call().then(result => +result).catch(e => {  return 0; })
        });
        return await Promise.all(tokenPromises).then(results => results);
    }

    return [];
};

async function claimPrize(currentAddress, tokenId) {
    if (getFlippeningContract()) {
        const sendData = {
            from: currentAddress,
            gas: 300000,
           // gasPrice: web3.utils.toWei('1', 'gwei')
        };

        await getFlippeningContract().methods.claimPrize(tokenId.toString()).send(sendData).then(result => result);
    }
}

export {
    getPastEvents,
    getTokenInfoForMonth,
    buyToken,
    getOwnedTokens,
    getPrice,
    transferToken,
    getGitcoinBalance,
    recordFlippening,
    getFlippeningTime,
    claimPrize,
    getTokensPerMonth,
    getPayoutBalance,
    loadTokensPerMonth,
    getTokenInformation,
    donateToGitcoin, 
    getGitcoinHistorical,
}