import React, { useState, useEffect } from 'react';
import { range } from 'lodash'
import {  eachMonthOfInterval, getYear } from 'date-fns';
import howitworks from 'images/Eth_infograph_banner.jpg';
import nftimage from 'images/flippening.jpg';
import TokenCard from './home/TokenCard';

const Home = ({isFlipped, flippeningMonth}) => {
    const [year, setYear] = useState(+getYear(new Date()));
    const [months, setMonths] = useState(null);

    useEffect(() => {
        const interval = year ?
            // Months for the current year
            {
                start: new Date(year, +year === 2021 ? 4 : 0, 1),
                end: new Date(year, +year === 2025 ? 5 : 11, +year === 2025 ? 30: 31)
            }
            :
            // All months
            {
                start: new Date(2021, 4, 1),
                end: new Date(2025,5, 30)
            };

        setMonths(eachMonthOfInterval(interval));
    }, [year]);

    return (
        <>
            <div className="ui masthead container">
                <div className="ui center aligned inverted huge header title">
                    Flippening NFT
                </div>

                <img
                    src={howitworks}
                    alt="how it works"
                    className="ui fluid bordered rounded image"
                />

                <div className="ui segment info-segment">
                    <p>Ethflippening.com is a combination of an awesome Non-Fungible Token (NFT) and prediction market.</p>
                    <p>Now you can prove that you know that one day the market cap of ETH will ‘flip’ BTC - tokens can only be minted BEFORE the flippening occurs. </p>
                    <p>The first image created for each month has one hidden ETH logo, and the 31th (last) image produced each month has 31 hidden ETH logos (besides the dolphin's horn). This represents the ETH in one validator </p>
                    <p>To commemorate the increasing security of Proof of Stake, 51% of all sales will be returned to the community – 6% automatically allocated to the Gitcoin matching grant fund and 45% to a pool that will pay out to everyone who has a token with the month when the flippening actually occurs. BTC Maxis can play too, by betting no flippening by May 2025!</p>
                </div>

                <img
                    src={nftimage}
                    alt="logo"
                    className="ui fluid bordered rounded image"
                />
            </div>

            <div className="ui filter container">
                <div className="ui grid token-months-buttons">
                    {months &&
                    <div className="row">
                        <div className="column year-buttons">
                            {range(2021, 2026).map(year =>
                                <button
                                    key={year}
                                    className="ui button"
                                    onClick={() => setYear(year)}
                                >{year}</button>
                            )}

                            <button
                                key="all"
                                className="ui button"
                                onClick={() => setYear(null)}
                            >All
                            </button>
                        </div>
                    </div>
                    }
                </div>
            </div>

            {months &&
                <div className="ui weekly container ">
                    <div className="ui grid token-months-grid">
                        <div className="stretched row">
                            {months.map((month, idx) =>
                                <div key={month} className="four wide column month-column">
                                    <TokenCard
                                        date={month}
                                        isFlipped={isFlipped}
                                        isWinningMonth={idx === flippeningMonth}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Home;
