import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {getTokenInformation} from 'components/web3/Api';
import useConnection from 'components/web3/useConnection';
import {eachMonthOfInterval, format} from "date-fns";
import { getImageURL, getMonthRank } from '../web3/PictureMap';

const NftData = () => {
    const connection = useConnection()
    const [tokenInfo, setTokenInfo] = useState(null);
    const {tokenId} = useParams();

    useEffect(() => {
        if (connection) {
            getTokenInformation(tokenId).then(data => {
                setTokenInfo(data);
            });
        }
    }, [connection, tokenId]);

    const getMonth = () => {
        if (!tokenInfo) {
            return '';
        }

        const months = eachMonthOfInterval({
            start: new Date(2021, 4, 1),
            end: new Date(2025,6, 30)
        })

        return format(months[tokenInfo.month],'MMMM yyyy');
    }

   

    return (
        <div className="ui masthead container my-nfts">
            <h3 className="ui top attached header page-header">
                Token Information
            </h3>
            <div className="ui attached segment">
                <p style={{color: 'white'}}> The rank is the order that the token was purchased in for the given month. For instance, a rank of 5 means the token was the 5th token purchased that month and the image has 6 hidden eth logos </p>
                {tokenInfo ?
                    <table className="ui collapsing table">
                        <tbody>
                            <tr>
                                <td><strong>Token Id</strong></td>
                                <td>{tokenInfo.tokenId}</td>
                            </tr>
                            <tr>
                                <td><strong>Month</strong></td>
                                <td>{getMonth()}</td>
                            </tr>
                            <tr>
                                <td><strong>Claimed</strong></td>
                                <td>{tokenInfo.claimed ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <td><strong>Owner</strong></td>
                                <td>{tokenInfo.owner}</td>
                            </tr>
                            <tr>
                                <td><strong>Rank</strong></td>
                                <td>{getMonthRank(tokenInfo.tokenId)}</td>
                            </tr>
                            <tr>
                                <td><strong>Image URL</strong></td>
                                <td>{getImageURL(tokenInfo.tokenId)}</td>
                            </tr>
                            <tr>
                                <td><strong>URI (JSON)</strong></td>
                                <td>{tokenInfo.uri}</td>
                            </tr>
                        </tbody>
                    </table>
                    : null}
            </div>
        </div>
    );
}

export default NftData;
