import React, { useEffect, useState } from 'react';
import useConnection from 'components/web3/useConnection';
import ConnectionContext from 'components/web3/ConnectionContext';
import {getPrice, getGitcoinBalance, getFlippeningTime, getPayoutBalance, getGitcoinHistorical} from 'components/web3/Api';
import Header from 'components/layouts/Header';
import Footer from 'components/layouts/Footer';
import Routes from 'components/routes/Routes'
import {eachMonthOfInterval, isSameMonth, fromUnixTime} from 'date-fns';
import web3 from 'web3';

const App = () => {
    const connection = useConnection();
    const [prices, setPrices] = useState({});
    const [gitcoinBalance, setGitcoinBalance] = useState(0);
    const [gitcoinHistorical, setGitcoinHistorical] = useState(0);
    const [isFlipped, setIsFlipped] = useState(false);
    const [flippeningTime, setFlippeningTime] = useState(null);
    const [flippeningMonth, setFlippeningMonth] = useState(null);
    const [payoutBalance, setPayoutBalance] = useState(null);

    useEffect(() => {
        if (!connection) {
            return;
        }

        getPrice().then(prices => {
            setPrices(prices);

            // Get flippening status
            const flippeningRatio = "6179713906000000000";
            const currIsFlipped  = web3.utils.toBN(prices.btcEthPrice).lt(web3.utils.toBN(flippeningRatio))

            setIsFlipped(currIsFlipped);
            if (currIsFlipped) {
                 getFlippeningTime().then((time) => {
                     const winningDate = time ? new Date(fromUnixTime(time)) : null;
                     setFlippeningTime(time)

                     if (winningDate) {
                         const months = eachMonthOfInterval({
                             start: new Date(2021, 4, 1),
                             end: new Date(2025,6, 30)
                         })

                         months.forEach((month, idx) => {
                             if (isSameMonth(month, winningDate)) {
                                 setFlippeningMonth(idx);
                             }
                         });
                     }
                 });
            }
        });

        getGitcoinHistorical().then(balance => {
            setGitcoinHistorical(balance);
        });

        getGitcoinBalance().then(balance => {
            setGitcoinBalance(balance);
        });

        getPayoutBalance().then(balance => {
            setPayoutBalance(balance);
        });
    }, [connection]);

    return (
        <ConnectionContext.Provider value={connection}>
            <Header
                prices={prices}
                gitcoinBalance={gitcoinBalance}
                gitcoinHistorical={gitcoinHistorical}
                isFlipped={isFlipped}
                flippeningTime={flippeningTime}
                payoutBalance={payoutBalance}
            />
            <Routes
                isFlipped={isFlipped}
                flippeningMonth={flippeningMonth}
            />
            < Footer
                isFlipped={isFlipped}
                flippeningTime={flippeningTime}
                setFlippeningTime={setFlippeningTime}
                gitcoinBalance={gitcoinBalance}
                gitcoinHistorical={gitcoinHistorical}
            />
        </ConnectionContext.Provider>
    );
}

export default App;
