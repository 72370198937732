import React, {useState, useEffect, useContext, useCallback} from 'react';
import {getOwnedTokens} from 'components/web3/Api';
import MyNftCard from './myNfts/MyNftCard';
import ConnectionContext from 'components/web3/ConnectionContext';

const MyNfts = ({flippeningMonth}) => {
    const connection = useContext(ConnectionContext);
    const [ownedTokens, setOwnedTokens] = useState(null);

    const refreshTokens = useCallback(() => {
        getOwnedTokens(connection.currentAddress).then(tokens => {
            setOwnedTokens(tokens);
        })
    }, [connection.currentAddress]);

    useEffect(() => {
        if (!connection || !connection.currentAddress) {
            return;
        }

        refreshTokens();
    }, [connection, connection.currentAddress, refreshTokens]);

    return (
        <div className="ui masthead container my-nfts">
            <h3 className="ui top attached header page-header">
                My NFTs {ownedTokens ? `(${ownedTokens.length})` : ''}
            </h3>
            <div className="ui attached segment">
                <div className="transaction-notes">Note: transactions may take some time to mine, refresh page after a few mins</div>
                <div className="ui weekly container">
                    <div className="ui grid my-nfts-grid">
                        <div className="stretched row">
                            {ownedTokens ?
                                ownedTokens.map(token =>
                                    <div key={token.blockNumber} className="four wide column">
                                        <MyNftCard token={token} flippeningMonth={flippeningMonth} refreshTokens={refreshTokens}/>
                                    </div>
                                )
                                : <div className="ui active centered inline loader"></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyNfts;
