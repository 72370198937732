import React, {useEffect, useState, useMemo, useCallback, useContext} from 'react';
import { format, differenceInCalendarMonths } from 'date-fns';
import {getTokenInfoForMonth, buyToken} from 'components/web3/Api';
import web3 from 'web3';
import ConnectionContext from "../../web3/ConnectionContext";

const TokenCard = ({date, isFlipped, isWinningMonth}) => {
    const connection = useContext(ConnectionContext);
    const [tokenInfo, setTokenInfo] = useState(null);
    // The month index of the date, May 2021 == 0
    const monthIndex = useMemo(
        () => differenceInCalendarMonths(date, new Date(2021, 4, 1)),
        [date]
    );
    const [isPurchasing, setIsPurchasing] = useState(false);

    const refreshToken = useCallback(() => {
        if (!getTokenInfoForMonth()) {
            return;
        }

        getTokenInfoForMonth(monthIndex).then(info => {
            if (info.price) {
                setTokenInfo(info)
            }
        })
    }, [monthIndex]);

    useEffect(() => {
        refreshToken();
    }, [connection, refreshToken]);

    const purchaseToken = () => {
        if (isPurchasing) {
            return;
        }

        setIsPurchasing(true);
        buyToken(connection.currentAddress, monthIndex, tokenInfo.price)
            .then(()  => refreshToken())
            .finally(() => setIsPurchasing(false));
    }

    return (
        <>
            <h3 className={`ui top attached header ${monthIndex === 49 ? 'last-month' : ''}`}>
                {format(date,'MMMM yyyy')}

                {isWinningMonth ?
                    <div className="ui orange label eth-btc-label">Winner!</div>
                    : monthIndex === 49 ? <div className="no-flippening">No Flippening by June 1 2025</div> : ''
                }
            </h3>
            <div className={`ui attached segment ${monthIndex === 49 ? 'last-month' : ''}`}>
                {tokenInfo  ?
                    <table className="ui compact table">
                        <tbody>
                            <tr>
                                <td>Minted:</td>
                                <td>{tokenInfo.tokensMinted} of 31</td>
                            </tr>
                            <tr>
                                <td>Price:</td>
                                <td>{tokenInfo.tokensMinted < 31 ? web3.utils.fromWei(tokenInfo.price) + ' ETH' : '---'} </td>
                            </tr>
                            <tr>
                                <td>Next Price:</td>
                                <td>{tokenInfo.tokensMinted < 31 ? web3.utils.fromWei(tokenInfo.nextPrice) + ' ETH' : '---'}</td>
                            </tr>
                        </tbody>
                        {!isFlipped ?
                            <tfoot className="full width">
                                <tr>
                                    <td colSpan="2" className="buy-token-container">
                                        {tokenInfo.tokensMinted < 31 ?
                                            <button
                                                className={`ui mini button ${monthIndex === 49 ? 'last-month' : ''}`}
                                                onClick={purchaseToken}>
                                                {isPurchasing ?
                                                    <><div className="ui mini active inline inverted loader"></div> PURCHASING</>
                                                    :
                                                    <>Purchase for {web3.utils.fromWei(tokenInfo.price)} ETH</>
                                                }
                                            </button>
                                            :
                                            <button className="ui mini button sold-out-button">
                                                SOLD OUT!
                                            </button>
                                        }


                                    </td>
                                </tr>
                            </tfoot>
                        : null}
                    </table>
                    : <div className="ui active centered inline loader"></div>
                }
            </div>
        </>
    );
};

export default TokenCard;
