import React from 'react';
import ratio from 'images/ratio.jpg';

const Faq = () => {
    return (
        <div className="ui masthead container faq-page">
            <h3 className="ui top attached header page-header">
                FAQ
            </h3>
            <div className="ui attached segment">
                <div id="lipsum">
                    <h4>How do we REALLY know how this works?</h4>

                   <p> The contract is open source and posted on etherscan at <a href="https://etherscan.io/address/0x6fa8a38c98bc5c66790cfc7e892f19c777b2c7fc#code">https://etherscan.io/address/0x6fa8a38c98bc5c66790cfc7e892f19c777b2c7fc#code</a> </p>

                    It is important to understand that the contract code is the only authority on how the game will play out. The website is just to make interacting with the contract more pleasant.

                    ** PLAY AT YOUR OWN RISK. The game MUST proceed 100% EXACTLY as coded in the contract **


                    <h4>How does the contract know when the flippening occurs?</h4>

                    The contract reads the BTC/ETH price ratio from the Chainlink oracle contract. The flippening is defined as when the the price of one bitcoin is less than 6179713906000000000 wei (~6.179 ETH). 

                    The contract makes assumptions about the ratio based on the current (April 12, 2021) supply of 115,442,772 M ETH and 18,680,925 M BTC. Since future issuance / burning cannot be predicted 100%, there is a chance that there may be a very slight difference between the ‘actual’ flippening and when this contract determines that the flippening has occurred. However, since each NFT covers one month, it is very unlikely that such variability would cause the token for a different month to win.

                    <br />

                    <img src={ratio} alt='calculate flippenin'/>

                    <h4>Can’t you just ‘fix it’ though?,</h4>

                    NO. Neither the developers, nor the CIA, nor any cartel, gang, judge, religious leader, army nor anyone else can adjust the rules once the contract has been deployed.  This is what gives you confidence that no one can cheat (i.e., 'rug pull'). Ie, if we (or anyone) had the power to alter the ratio, then we could cause ourselves to win.

                    <h4>Will holding this NFT make me eligible for other airdrops?</h4>

                    Due to the permissionless and open nature of the blockchain, any project can choose to reward users of any other project. This project provides a verified, exclusive list of people who believed in the flippening before it happened. It’s not crazy to think that some future project might want to reward those addresses or value their input in governance.

                    <h4>What is the scarcity of this NFT?</h4>

                    There can be a maximum of only 31 tokens purchased for each month from May 2021 to June 2025. This number was chosen to be approximately equal to one per day. However, as the price per token increases as more tokens are sold, it is likely that most months will have less than the maximum amount issued. (Similar to how there can be a maximum of 500 Uni Socks, but the majority have not been minted). It is important to note, that there are actually 31 separate versions of this NFT with a different amount of ETH logos hidden in the image for each.

                    <h4>What is the pricing curve?</h4>

                    You can see the full pricing curve in the contract or the 'how it works' page.

                    <h4>What if I don’t win?</h4>

                    Everyone wins by owning an amazing NFT depicting the flippening mascot, a majestic unicorn dolphin bursting from the sea and casting its shadow over a bitcoin which simply rests on its laurels. The pool for having a NFT with the correct month is simply a bonus.

                    <h4>What can I do with this NFT?</h4>

                    You really own it, you can do whatever you want with it. You can see details about it or transfer it through our site, sell it on Opensea, donate it to charity, put it in your will, etc.

                    <h4>What if the image disappears and I get  <a href='https://www.youtube.com/watch?v=_V0-HGMB8SI'> the most expensive 404 error </a> ever?</h4>

                    Come on, son! Interplanetary File System (IPFS) has got you covered. This entire front end as well as the high resolution JPGs are stored at hash ….. and pinned for many years through https://www.pinata.cloud You are welcome to pin as well for even more redundancy.

                    <h4>How do I know Gitcoin is receiving its funds?</h4>

                    The balance due to gitcoin updates on every transaction. Anyone willing to pay for gas can call the donateToGitcoin() public function which will transfer the entire balance to the gitcoin matching grants multisig wallet.

                    <h4>Can BTC Maxis play too?</h4>

                    Sure…  They can buy the token for June 2025, which will pay out if there is no flippening by that date.

                    <h4>Why did my transaction fail?</h4>

                    It’s possible (though unlikely) that someone else had tried to buy a token with the same month you did (great minds think alike) but paid a higher gas fee. Because each NFT is unique, only one address can purchase it. You can purchase again, but the price may be slightly higher.

                    <h4>How do you deal with timezones?</h4>

                    All timestamps are UTC

                    <h4>How do winners claim?</h4>

                    Winners can claim their share of the winnings by sending a transaction to the public claim(tokenId) function. There is no limit to the time after the flippening that you can claim. The contract will hold your rewards until you claim.

                    <br /><br />
                    <b>Important -</b> at least ONE person must call either the claim() function (winning addresses only) or the public recordFlippening() function (anyone can call) during the month where the flippening first happens so that the contract will record that the flippening has happened.

                    <br /><br />
                    Devs plan to call the recordFlippening() function, but they can be called by anyone if for any reason devs are unable to


                    <h4>I still have an unanswered question about.....</h4>

                    Please check the contract source code, as it is the ultimate, immutable authority on what will happen. 

                   <h4>Some Links</h4>

                    https://www.flippening.watch/
                        <br />



                    https://ratiogang.com/
                </div>
            </div>
        </div>
    );
}

export default Faq;
