const pictureURLs = [
    'QmbPqWiNwSw4TvTJWXEiU9h1VsXSSawka8MdKW8Njy26su',
    'QmYwaRhQc78jdi9PtZT8Rf7DVdcn68F2NEUHiEQx6NQyec',
    'QmU7qT8hUAs1sNhtUJAVD6rtxDvuTxBouVYVKw8ziRk1zd',
    'QmUKbsxPEs73RmjWcyVdDkzJcUkJyDoAF9sqv5Fvw8nXfj',
    'QmZBiSgneEcVwq8ipyAjdF7pfivFGEpEuZfZx8L7vtuhFy', //5
    'QmcQF1bSKNmrixc6V9eJwy61ndeR53xHESUqsdQYbsuh23',
    'QmZ9HZVZqsBKXqLnLg7EfHV45P7VCAZm2da4XVCAGUYcEj',
    'QmfCvUz7sZfSqHEKinJZFT78zFuFWjbpuBafYPbc3YereQ',
    'QmRLD2nQ7u4k9pLtbdAJ7QTpXjax1hi6tut419AdcxmyDR',
    'QmRaTuWxMBxvkn7auAqL4NM4R5XknaLpM3jfy6UYW1Gv2a',//10
    'QmQusRd3xxpXscLXoRP2KuiUwS8d31hBceC5h6uSJvD9B6',
    'QmaTQ3HW4hsChDvreGX2JdyCUKPTdqAXVdNcM4zdXR8NxX',
    'QmbvfFkR8irHABvHj9y8VgwqBjWx7WCa4cTf8xxF1nm4Sf',
    'QmVv9iM7har9QqjsqvBpnxWEBsStGv7rdHJzJ7Ybu4aXqe',
    'QmXEjYUmNEryGnZQS67oR1bHGQ3s14Ld29LAzKtGwtmSsc',//15
    'QmPZ5J9YjPkSNTMWkAff2iMRi343WvxZo6f4W4QUp2iuYp',
    'QmYkMjsFbbp7cHoYbinNDAbDZXtRVadAnq8c6EV5CnjJFF',
    'QmZhJJ93XAx2sdpCESnGmoHeVbu8YyKbNPqQQNnkEiifQj',
    'QmbeEy2i8xeZNBkJtp6N7P9hEAgchZgBinz1L1Kz9GwV3N',
    'QmQxNKkzKmFdsEfa45XYbVeKRz6LRM8GTUEnusyUFjzyNv', //20
    'QmNp8UBh78k8eHL3h8aWNA3yR4UEE6hqxZctyPmUz8q2G5',
    'QmaU9TZucjESdtP2pERVxfJ8eG2rg9Kjfw1gK7kmkL6Z1p',
    'QmZZxXZx7N5dpKxZofgKKaixbypA5CzpriGtkzWATcFiMY',
    'QmSDjCCtBNYNNGrwnK8o9hhhh3gPQy2umY4Z86hidZtSAJ',
    'QmchqRLXSMKj5t3cTbxuDY93exJgW2sWTsBs8g6j2uTtU7', //25
    'QmUBoYRJbW6S4bMCvTnfa6UyJZka7M3Cyo6ZFAL5FdexdW',
    'QmVfKAG5bX661kB7gq313c3nxJwnxeZqc2VkY4J2vSexTt',
    'QmYwC6bGEPHmnweCveJUC976EvHJK9uLDvRio922TaJukK',
    'QmcD5EyZUHPwqgBgXWqQix9ZEeHUK9dtCg5SJnMqFqKyPv',
    'QmcwzBaS57oTvD1kGBhgeumBYVpWhQB6CvHkWDU82xVNWT', //30
    'QmS2dF6ySDsf9KeFu2L9ecPf24t6AAjFvrnwgv8wjnbVDJ',
]


export function getImageURL(id) {
 
    const tokenMonthRank = JSON.parse(localStorage.getItem('tokenMonthRank'))
    const numTokens = parseInt(localStorage.getItem('numTokens'), 10)
 
    // MyNftCard component will refresh when local storage has finished writing
    if (id > numTokens) {
        return 'Loading...'
    }

    let monthRank = -1
    for (let i = 0; i < tokenMonthRank.length; i++) {
        if (tokenMonthRank[i].tokenId === id) {
            monthRank = parseInt(tokenMonthRank[i].monthRank, 10);
        }
    }

    //check off by 1 error
    if (monthRank === -1) {
        return 'Refresh Page'
    }
   
    return 'https://ipfs.io/ipfs/' + pictureURLs[monthRank - 1]
}

export function getMonthRank(id) {

    const tokenMonthRank = JSON.parse(localStorage.getItem('tokenMonthRank'))
    const numTokens = parseInt(localStorage.getItem('numTokens'), 10)

    // MyNftCard component will refresh when local storage has finished writing
    if (id > numTokens) {
        return 'Loading...'
    }

    let monthRank = -1
    for (let i = 0; i < tokenMonthRank.length; i++) {
        if (+tokenMonthRank[i].tokenId === +id) {
            monthRank = parseInt(tokenMonthRank[i].monthRank, 10);
        }
    }

    //check off by 1 error

    if (monthRank === -1) {
        return 'Error'
    }

    return monthRank
}