import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Home from 'components/views/Home';
import Faq from 'components/views/Faq';
import MyNfts from 'components/views/MyNfts';
import HowItWorks from 'components/views/HowItWorks';
import NftData from 'components/views/NftData';

const Routes = ({isFlipped, flippeningMonth}) => {
    return (
        <Switch>
            <Route path="/" exact>
                <Home
                    isFlipped={isFlipped}
                    flippeningMonth={flippeningMonth}
                />
            </Route>
            <Route path="/faq">
                <Faq />
            </Route>
            <Route path="/my-nfts">
                <MyNfts flippeningMonth={flippeningMonth} />
            </Route>
            <Route path="/how-it-works">
                <HowItWorks />
            </Route>
            <Route path="/nftdata/:tokenId">
                <NftData />
            </Route>
            <Route>
                <Redirect to="/" />
            </Route>
        </Switch>
    );
}

export default Routes;