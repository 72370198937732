import React, { useState, useContext, useMemo } from 'react';
import {Link} from 'react-router-dom';
import {eachMonthOfInterval, format} from 'date-fns';
import { transferToken } from 'components/web3/Api';
import ConnectionContext from 'components/web3/ConnectionContext';
import {claimPrize} from 'components/web3/Api';
import { getImageURL } from '../../web3/PictureMap';

const MyNftCard = ({token, flippeningMonth, refreshTokens}) => {
    const connection = useContext(ConnectionContext);
    const [transferModalVisible, setTransferModalVisible] = useState(false);
    const [transferToAccount, setTransferToAccount] = useState('');
    const [invalidAddress, setInvalidAddress] = useState(true);
    const [isTransferring, setIsTransferring] = useState(false);
    const [isClaiming, setIsClaiming] = useState(false);
    const [transferred, setTransferred] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    // Grab the image if it has been loaded in local storage. If not, 
    // the result will be 'loading...'
    const [imageURL, setImageURL] = useState(getImageURL(parseInt(token.tokenId, 10)))

    window.onstorage = () => {
        const numTokens = window.localStorage.getItem('numTokens')

        // If the token was a higher id and needed to be read from the chain
        if (token.tokenId > numTokens) {
            setImageURL(getImageURL(parseInt(token.tokenId, 10)))
        }
    };
    const months = useMemo(() =>
                eachMonthOfInterval({
                    start: new Date(2021, 4, 1),
                    end: new Date(2025,6, 30)
                }), []);

    const handleChange = (e) => {
        const value = e.target.value;
        setTransferToAccount(value);
        setInvalidAddress(!value.trim());
        setErrorMessage(null);
    };

    const handleClaim = () => {
        setIsClaiming(true);
        claimPrize(connection.currentAddress, token.tokenId).then(() => {
            token.claimed = true;
            refreshTokens();
        }).finally(() => setIsClaiming(false));
    }

    const transfer = () => {
        if (invalidAddress) {
            return;
        }

        setInvalidAddress(false);
        setIsTransferring(true);
        setErrorMessage(null);
        transferToken(connection.currentAddress, transferToAccount, token.tokenId)
            .then((results) => {
                setTransferred(true);
            })
            .catch((e) => {
                const name = e.name ? e.name.trim() : '';
                const message = e.message;

                if (message.includes('User denied transaction signature')) {
                    setErrorMessage('Transaction cancelled');
                }
                else if (message.includes('ERC721: transfer of token that is not own')) {
                    setErrorMessage('You do not own this token');
                }
                else if (message.includes('invalid address')) {
                    setErrorMessage('Invalid address provided');
                }
                else {
                    setErrorMessage('Unknown error: ' + name);
                }

            }).finally(() => setIsTransferring(false));
    };

    const closeTransferModal = () => {
        !isTransferring && setTransferModalVisible(false);
    }

    const getClaimButton = () => {
        if (!flippeningMonth || token.month !== flippeningMonth.toString() ) {
            return;
        }

        return token.claimed ?
            <div className="ui green label eth-btc-label">Claimed</div>
            :
            <div className="ui orange label eth-btc-label" onClick={handleClaim}>
                {!isClaiming ?
                    'Claim Your ETH!'
                    :
                    <div className="ui active centered mini inline loader"></div>
                }
            </div>;
    }

    return (
        <>
            <h4 className="ui top attached header">
                Token id - {token.tokenId}{' '}
                {getClaimButton()}
                {transferred ? <span className="transferred-label">(TRANSFERRED)</span> : null}
            </h4>
            <div className="ui attached segment">
                <table className="ui compact table">
                    <tbody>
                        <tr>
                            <td>Month Predicted:</td>
                            <td>
                                {token.month !== '' ?
                                    format(months[token.month], 'MMMM yyyy')
                                    :
                                    'unknown'
                                }
                            </td>
                        </tr>
                        {imageURL !== 'Loading...' && imageURL !== 'Refresh Page' ? <tr>
                            <td>Image URL:</td>
                            <td><a href={imageURL}>Full Image</a></td>
                        </tr> : 
                            <tr>
                                <td>Image URL:</td>
                                <td>{imageURL}</td>
                            </tr>
                            }
                    
                            <tr>
                                <td>Full Info:</td>
                                <td>
                                    <Link to={`/nftdata/${token.tokenId}`}>link</Link>
                                </td>
                            </tr>
                            <tr>
                            <td colSpan="2" className="ui center aligned">
                                {!transferred ?
                                    <button
                                    className="ui grey mini button"
                                    onClick={() => setTransferModalVisible(true)}
                                    >Transfer</button>
                                    :
                                    <button
                                        className="ui grey mini button"

                                    >Transferred</button>}
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
            {transferModalVisible && !transferred ?
                <div className="ui dimmer modals page transition visible active transfer-modal"
                     onClick={closeTransferModal}
                >
                    <div className="ui active tiny active modal" onClick={(e) => e.stopPropagation()}>
                        <div className="header">
                            Transfer Token: {token.tokenId}
                        </div>
                        <div className="content">
                            <div className="ui one column center aligned divided grid">
                                {errorMessage &&
                                    <div className="row ui form">
                                        <div className="column error-message">
                                            {errorMessage}
                                        </div>
                                    </div>
                                }
                                <div className="row ui form">
                                    <div className="column">
                                        <div className="field">
                                           <label>Transfer To:</label>
                                           <input
                                               id="transferToAddress"
                                               type="text"
                                               size="42"
                                               maxLength="42"
                                               placeholder="0x0000000000000000000000000000000000000000"
                                               value={transferToAccount}
                                               onChange={handleChange}
                                           />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="column">
                                        {!invalidAddress && !transferred && !isTransferring? <button className="ui positive button" onClick={() => transfer()}>
                                            Transfer
                                        </button> : null }

                                        {isTransferring ?
                                            <button className="mini ui loading button">Transferring</button>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    );
};

export default MyNftCard;
