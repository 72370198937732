import { flippeningABI } from './abis/flippeningABI.js';
import chainlinkEthPriceFeedABI from './abis/chainlinkEthPriceFeedABI';

let flipppeningContract = null;
function getFlippeningContract() {
    return flipppeningContract;
}

let btcEthPriceContract = null;
function getBtcEthPriceContract() {
    return btcEthPriceContract;
}

function initContractsWithWeb3(web3) {
    /* Mainnet */
    btcEthPriceContract = new web3.eth.Contract(chainlinkEthPriceFeedABI, "0xdeb288F737066589598e9214E782fa5A8eD689e8");
    flipppeningContract = new web3.eth.Contract(flippeningABI, "0x6Fa8A38C98bc5c66790cfC7e892f19c777B2c7FC");

/* Kovan */
 
    //flipppeningContract = new web3.eth.Contract(flippeningABI, "0x3a9a1c6b5e3d4c3ee315ae63412e7aea07b7ca5b");
   // flipppeningContract = new web3.eth.Contract(flippeningABI, "0xF839fdcF09435016279BDEa13f7311ff6690fc4B");

   // btcEthPriceContract = new web3.eth.Contract(chainlinkEthPriceFeedABI, "0xF7904a295A029a3aBDFFB6F12755974a958C7C25");
}

export { getFlippeningContract, getBtcEthPriceContract, initContractsWithWeb3}